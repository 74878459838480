import React from 'react';
import style from './style.css';
import PropTypes from 'prop-types';
import CalendarIcon from 'cccisd-icons/calendar-empty';
import Loader from 'cccisd-loader';
import { client, gql } from 'cccisd-apollo';
import CccisdToggle from '../CustomCccisdToggle';
import Table from '../ScoresTable';
import classListQuery from './classListQuery.graphql';
import fetchStudentData from './fetchStudentData.graphql';
import _get from 'lodash/get';
import Viz from './Viz';

export const ineligibleScoringKey = 'Locked';

const Fortress = window.cccisd && window.cccisd.fortress;

export default class PeerNom extends React.Component {
    static propTypes = {
        tableProps: PropTypes.object.isRequired,
        selectedClass: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        timepoint: PropTypes.string.isRequired,
        assignmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        classList: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.groupId = Fortress.user.acting.group_id;
        this.currentRole = Fortress.user.acting.role.handle;
        // persisting some state in session storage, but don't use if it's a different user
        let initialSelectedClass = props.selectedClass || '';
        let initialIsShowingViz = true;
        let currentPawnId = Fortress.user.acting.id;
        let previousPawnId = sessionStorage.getItem('pawnId');
        if (String(currentPawnId) === String(previousPawnId)) {
            initialIsShowingViz = sessionStorage.getItem('isShowingViz') === 'false' ? false : true;
            if (this.currentRole !== 'instructor') {
                initialSelectedClass = parseInt(sessionStorage.getItem('selectedClass'), 10) || '';
            }
        }
        sessionStorage.setItem('pawnId', currentPawnId);

        this.state = {
            isLoading: true,
            selectedClass: initialSelectedClass, // Passed down by instructors, but not admins
            classList: this.props.classList || [], // Passed down by instructors, but not admins
            isShowingViz: initialIsShowingViz,
            studentData: null,
            isDemographicFilter: false,
        };
    }

    componentDidMount() {
        if (['guAdmin', 'siteAdmin', 'uberadmin'].includes(this.currentRole)) {
            this.fetchClassList();
        } else if (this.state.selectedClass && this.state.classList) {
            this.fetchStudentData();
        }
    }

    componentWillUnmount() {
        sessionStorage.setItem('selectedClass', this.state.selectedClass);
        sessionStorage.setItem('isShowingViz', this.state.isShowingViz);
    }

    componentDidUpdate(prevProps, prevState) {
        // For instructor role that passes down selectedClass ID
        if (prevProps.selectedClass !== this.props.selectedClass) {
            this.setState({ selectedClass: this.props.selectedClass }, () => this.fetchStudentData()); //eslint-disable-line
        }
        if (this.state.selectedClass !== prevState.selectedClass || prevProps.timepoint !== this.props.timepoint) {
            this.fetchStudentData();
        }
    }

    fetchStudentData = () => {
        this.setState({ isLoading: true }, async () => {
            const response = await client.query({
                query: gql`
                    ${fetchStudentData}
                `,
                variables: {
                    deploymentHandle: `pnscoring_${this.props.timepoint}`,
                    classId: this.state.selectedClass,
                    assignmentId: this.props.assignmentId,
                    timepoint: this.props.timepoint,
                },
                fetchPolicy: 'network-only',
            });

            const isDemographicFilter = _get(
                response,
                'data.groups.class.ancestorGroups.groupingUnit.fields.vizcontrols',
                false
            );

            this.setState({
                isLoading: false,
                studentData: response.data.roles.learnerList,
                isDemographicFilter,
            });
        });
    };

    fetchClassList = () => {
        this.setState({ isLoading: true }, async () => {
            const response = await client.query({
                query: gql`
                    ${classListQuery}
                `,
                variables: { assignmentId: this.props.assignmentId },
                fetchPolicy: 'network-only',
            });

            const classList = _get(response, 'data.groups.classList', []).filter(c => {
                let hasPnModules = false;
                c.selectedAssignmentPlan.sessionList.forEach(session => {
                    session.settings.options.forEach(surveyHandle => {
                        if (surveyHandle.startsWith('pn_')) hasPnModules = true;
                    });
                });
                return hasPnModules;
            });

            let selectedClass = this.state.selectedClass || (classList.length > 0 && classList[0].group.groupId);
            this.setState(
                {
                    isLoading: false,
                    classList,
                    selectedClass,
                },
                () => {
                    if (this.state.selectedClass) {
                        this.fetchStudentData();
                    }
                }
            );
        });
    };

    renderClassPickerOptions = () => {
        let { classList } = this.state;

        if (classList.length === 0) {
            return <option value="">No classes to display.</option>;
        }
        if (this.currentRole === 'siteAdmin') {
            return classList.map(c => (
                <option value={c.group.groupId} key={c.group.groupId}>
                    {c.group.label}
                </option>
            ));
        }
        if (['guAdmin', 'uberadmin'].includes(this.currentRole)) {
            let uniqueSiteLabels = new Set();
            classList.forEach(classData => {
                const siteName = _get(classData, 'parentGroup.site.group.label', 'Other');
                uniqueSiteLabels.add(siteName);
            });

            return [...uniqueSiteLabels].map(siteName => (
                <optgroup label={siteName} key={siteName}>
                    {classList.map(
                        classData =>
                            classData.parentGroup.site.group.label === siteName && (
                                <option value={classData.group.groupId} key={classData.group.groupId}>
                                    {classData.group.label}
                                </option>
                            )
                    )}
                </optgroup>
            ));
        }
    };

    renderScoresTable = () => {
        let { tableProps, assignmentId, timepoint } = this.props;
        let { selectedClass } = this.state;

        const customRender = ({ data, renderDefault }) => {
            const updateIneligibleStudent = student => {
                Object.keys(student).forEach(key => {
                    if (
                        [
                            'pnscores.devTags.pn_externalizing_composite',
                            'pnscores.devTags.pn_internalizing_composite',
                            'pnscores.devTags.pn_prosocial_composite',
                            'pnscores.devTags.pn_rejected_composite',
                            'pnscores.devTags.pn_social_preference_composite',
                        ].includes(key)
                    ) {
                        student[key] = ineligibleScoringKey;
                    }
                });
                return student;
            };

            let pnLockWhiteList;
            try {
                pnLockWhiteList = JSON.parse(
                    this.state.classList.find(c => String(c.group.groupId) === String(selectedClass)).fields.pn_locks
                ).find(pnLock => pnLock.timepoint === timepoint);
            } catch (e) {
                // Leave undefined
            }

            let isAnyLocked = false;
            // If there are students, but none have started yet,
            // pnLockWhiteList will be missing but don't show "Locked"
            if (pnLockWhiteList) {
                data = data.map(student => {
                    if (!pnLockWhiteList.locked.includes(student['pawn.pawnId'])) {
                        isAnyLocked = true;
                        student = updateIneligibleStudent(student);
                    }
                    return student;
                });
            }

            return (
                <div className={style.scoresTable}>
                    {renderDefault(data)}
                    {isAnyLocked && (
                        <p className={style.tableNote}>
                            Note: Scores denoted by &quot;{ineligibleScoringKey}&quot; mean that the student is
                            ineligible for scoring.
                        </p>
                    )}
                </div>
            );
        };

        return (
            <Table
                render={customRender}
                {...tableProps}
                graphqlVariables={{
                    classId: selectedClass,
                    assignmentId,
                    timepoint,
                    deploymentHandle: `pnscoring_${timepoint}`,
                }}
                noRecordsMessage="No students to display."
            />
        );
    };

    checkForSeventyPercentComplete = () => {
        if (this.state.isLoading || !this.state.studentData) {
            return true;
        }

        let pnLockWhiteList;
        try {
            pnLockWhiteList = JSON.parse(_get(this.state.studentData, '[0].parentGroup.class.fields.pn_locks')).find(
                pnLock => pnLock.timepoint === this.props.timepoint
            ).locked;
        } catch (e) {
            // If no pnLock, it might be because no students have started yet
            if (!this.state.studentData.length) {
                return true;
            }
            return false;
        }

        let countStudentsComplete = 0;
        this.state.studentData.forEach(student => {
            if (student.ee.completed) {
                countStudentsComplete++;
            }
        });
        return countStudentsComplete / pnLockWhiteList.length > 0.7;
    };

    checkIsSessionStarted = () => {
        if (this.state.isLading || !this.state.studentData) {
            return true;
        }

        let now = new Date();
        let sessionStartDate = new Date(
            _get(this.state.studentData, '[0].parentGroup.class.selectedAssignmentPlan.session.opensDate', null)
        );
        return now > sessionStartDate;
    };

    onClassSelect = selectedClass => {
        this.setState({ selectedClass });
    };

    render() {
        const { isLoading, classList, selectedClass, isShowingViz, studentData } = this.state;
        const isSeventyPercentComplete = this.checkForSeventyPercentComplete();
        const isSessionStarted = this.checkIsSessionStarted();
        return (
            <Loader loading={isLoading}>
                <div>
                    <div className={style.vizToggleContainer}>
                        {['guAdmin', 'siteAdmin', 'uberadmin'].includes(this.currentRole) && (
                            <>
                                <label htmlFor="classlist">Class&nbsp;&nbsp;</label>
                                <select
                                    value={selectedClass}
                                    name="classList"
                                    id="classList"
                                    onChange={e => this.onClassSelect(parseInt(e.target.value, 10))}
                                    className={style.classPicker}
                                >
                                    {this.renderClassPickerOptions()}
                                </select>
                            </>
                        )}
                        {isSeventyPercentComplete && isSessionStarted && <label>Visualization&nbsp;&nbsp;</label>}
                        {isSeventyPercentComplete && isSessionStarted && (
                            <CccisdToggle
                                value={isShowingViz}
                                onChange={() => this.setState({ isShowingViz: !isShowingViz })}
                            />
                        )}
                    </div>
                    {isShowingViz && studentData && isSeventyPercentComplete && isSessionStarted && (
                        <Viz
                            timepoint={this.props.timepoint}
                            studentData={this.state.studentData}
                            isDemographicFilter={this.state.isDemographicFilter}
                        />
                    )}
                    {!isSessionStarted && (
                        <p className={style.warningBanner}>
                            <span>
                                <CalendarIcon />
                            </span>
                            &nbsp;&nbsp;Data collection for time {this.props.timepoint} will begin{' '}
                            {_get(
                                studentData,
                                '[0].parentGroup.class.selectedAssignmentPlan.session.opensDate',
                                'at a later date'
                            )}
                            .
                        </p>
                    )}
                    {!isSeventyPercentComplete && isSessionStarted && (
                        <p className={style.warningBanner}>
                            In order to show data, at least 70% of eligible students must complete the assignment.
                        </p>
                    )}
                    {selectedClass &&
                        studentData &&
                        classList.length > 0 &&
                        isSessionStarted &&
                        this.renderScoresTable()}
                </div>
            </Loader>
        );
    }
}
