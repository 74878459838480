import React from 'react';
import style from './style.css';
import { Link } from 'react-router-dom';
import { client, gql } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import _get from 'lodash/get';
import NotificationIcon from 'cccisd-icons/notification';
import Administrative from '../Administrative';

export default class TeacherAdminstrative extends React.Component {
    state = {
        isLoading: true,
        classList: [],
        selectedClassId: '',
        isRatingForm: '', // one of ['', 'complete', or 'incomplete']

        // all archived reports show in Reports tab regardless of
        // which class is currently selected, so only fetch once
        archivedReportFileList: [],
    };

    componentDidMount() {
        this.fetchInitialData();
    }

    fetchInitialData = async () => {
        await this.fetchClassList();
        this.setState({ isLoading: false });
    };

    fetchClassList = async () => {
        const { classListQuery } = this.props;
        const response = await client.query({
            query: gql`
                ${classListQuery}
            `,
            fetchPolicy: 'network-only',
        });

        const classList = _get(response, 'data.groups.classList', []);
        const isRatingForm = false;
        const selectedClassId = classList.length > 0 ? classList[0].group.groupId : '';
        this.setState({ isRatingForm, classList, selectedClassId });
    };

    setClassId = selectedClassId => {
        this.setState({ selectedClassId });
    };

    render() {
        const { isRatingForm, classList, selectedClassId, isLoading, archivedReportFileList } = this.state;

        return (
            <Loader loading={isLoading}>
                {isRatingForm === 'incomplete' && (
                    <p className={style.ratingFormBanner}>
                        <span className={style.icon}>
                            <NotificationIcon />
                        </span>
                        &nbsp; Time to complete &nbsp;<Link to="/ratingForm">Teacher Surveys</Link>
                    </p>
                )}
                {isRatingForm === 'complete' && (
                    <div className={style.ratingFormComplete}>
                        <span className=" glyphicon glyphicon-ok" /> Teacher Surveys Completed
                    </div>
                )}
                {classList.length > 1 && (
                    <select
                        name="classList"
                        id="classList"
                        value={selectedClassId}
                        onChange={event => this.setClassId(parseInt(event.target.value, 10))}
                        className={style.classSelect}
                    >
                        {!selectedClassId && (
                            <option value="" key="placeholder">
                                Select a class
                            </option>
                        )}
                        {classList.map(c => (
                            <option value={c.group.groupId} key={c.group.groupId}>
                                {c.group.label}
                            </option>
                        ))}
                    </select>
                )}
                {!isLoading && (
                    <Administrative
                        key={selectedClassId}
                        {...this.props}
                        classId={selectedClassId}
                        archivedReportFileList={archivedReportFileList}
                    />
                )}
            </Loader>
        );
    }
}
