import React from 'react';
import { isFuture } from 'date-fns';
import GqlTable from 'cccisd-graphql-table';
import CalendarIcon from 'cccisd-icons/calendar-empty';
import CccisdRenders from 'cccisd-table-renders';
import { ineligibleScoringKey } from '../PeerNom';
import UploadImage from '../../renders/UploadImage';
import style from './style.css';

const Appdefs = window.cccisd && window.cccisd.appDefs;

const ScoresTable = props => {
    const customRenderCheckTimepointDate = ({ data, renderDefault }) => {
        let createdPlanOpensDateMapper = {};
        if (data.length > 0) {
            const allPlans = data[0]['ancestorGroups.groupingUnit.createdAssignmentPlanList'] || [];
            allPlans.forEach(plan => {
                createdPlanOpensDateMapper[plan.assignmentPlanId] = plan?.session?.opensDate;
            });
        }

        const listOfSessionOpensDates = data.reduce((allDates, learner) => {
            // assigned plans from class level
            if (learner['ancestorGroups.class.selectedAssignmentPlan.assignmentPlanId']) {
                const opensDate = learner['ancestorGroups.class.selectedAssignmentPlan.session.opensDate'];
                if (opensDate) {
                    allDates.push(opensDate);
                }
                return allDates;
            }

            // default plans on each individual student defined at district level
            const grade = learner['fields.grade'];
            const defaultAssignmentPlanId =
                grade && learner[`ancestorGroups.groupingUnit.group.settings.defaultAssignmentPlans.plan_${grade}`];
            if (createdPlanOpensDateMapper[defaultAssignmentPlanId]) {
                allDates.push(createdPlanOpensDateMapper[defaultAssignmentPlanId]);
            }

            return allDates;
        }, []);

        const earliestOpensDate = listOfSessionOpensDates.reduce(
            (min, curr) => (curr < min ? curr : min),
            '9999-01-01'
        );

        if (listOfSessionOpensDates.length && isFuture(new Date(earliestOpensDate))) {
            return (
                <p className={style.warningBanner}>
                    <span>
                        <CalendarIcon />
                    </span>
                    &nbsp;&nbsp;Data collection for{' '}
                    {props.selectedTimepoint ? `time ${props.selectedTimepoint}` : 'this time'} will begin on{' '}
                    {earliestOpensDate}.
                </p>
            );
        }
        return renderDefault(data);
    };

    const ColorByCategory = ({ value }) => {
        // CCCQD-919: keep original category values (Well Below Expectations, Below Expecations, etc...)
        // To change styles or display text, just change appdefs
        const displayTextMapper = Appdefs.app.category_display_text_mapper;
        const styleMapper = Appdefs.app.category_style_mapper;
        const styles = styleMapper[value] || {};
        const displayText = displayTextMapper[value] || value;
        return <div style={{ ...styles, padding: '0.2em', borderRadius: '4px' }}>{displayText}</div>;
    };

    const ColorByMSCategory = ({ value }) => {
        // CCCQD-919: keep original category values (Well Below Expectations, Below Expecations, etc...)
        // To change styles or display text, just change appdefs
        const displayTextMapper = Appdefs.app.category_ms_display_text_mapper;
        const styleMapper = Appdefs.app.category_ms_style_mapper;
        const styles = styleMapper[value] || {};
        const displayText = displayTextMapper[value] || value;
        return <div style={{ ...styles, padding: '0.2em', borderRadius: '4px' }}>{displayText}</div>;
    };

    const IneligibleScore = ({ value }) => {
        if (value === ineligibleScoringKey) {
            return <span style={{ fontStyle: 'italic', color: 'rgb(150, 150, 150)' }}>{value}</span>;
        }
        return <span>{value}</span>;
    };

    const Status = ({ value }) => {
        if (value === null) {
            value = 'Not Started';
        }
        return <span>{value}</span>;
    };

    const Image = ({ value: imageUrl }) => {
        if (!imageUrl) {
            return null;
        }

        return <div className={`text-center ${style.tableImage}`} style={{ backgroundImage: `url(${imageUrl})` }} />;
    };

    const Audio = ({ row }) => {
        return CccisdRenders.html.Audio({ audioUrlPath: 'fields.pn_audio_url' })({ row });
    };

    const UpperCase = ({ value }) => {
        if (typeof value !== 'string') {
            return <span>{value}</span>;
        }
        return <span>{value.charAt(0).toUpperCase() + value.slice(1)}</span>;
    };

    const Grade = ({ value }) => {
        return <span>{value === '0' ? 'K' : value}</span>;
    };

    const PercentCompleted = ({ row }) => {
        try {
            let classData = props.classList.find(c => c.group.groupId === row['group.groupId']);
            let isTimepointAssigned = classData.selectedAssignmentPlanList.some(
                assignmentPlan =>
                    assignmentPlan.role === 'learner' &&
                    assignmentPlan.sessionList.some(session => session.deployment.timepoint === props.timepoint)
            );
            if (!isTimepointAssigned) {
                return <span>Not assigned</span>;
            }
        } catch (e) {
            // do nothing, it will just display 0's instead of "Not assigned", which is usually appropriate
        }

        const totalLearners = row['descendantRoles.total'];
        const completedLearners = row['descendantRoles.completed'];
        let percent = Math.round((completedLearners / totalLearners) * 100);
        if (totalLearners === 0) {
            percent = 0;
        }

        return <span>{percent}%</span>;
    };

    const CheckIsTimepointAssigned = ({ row, value }) => {
        try {
            let classData = props.classList.find(c => c.group.groupId === row['group.groupId']);
            let isTimepointAssigned = classData.selectedAssignmentPlanList.some(
                assignmentPlan =>
                    assignmentPlan.role === 'learner' &&
                    assignmentPlan.sessionList.some(session => session.deployment.timepoint === props.timepoint)
            );
            if (!isTimepointAssigned) {
                return <span>Not assigned</span>;
            }
        } catch (e) {
            // do nothing, it will just display 0's instead of "Not assigned", which is usually appropriate
        }

        return value;
    };

    const InstructorList = ({ value }) => {
        return (
            value && (
                <ul className={style.instructorList}>
                    {value.map(teacher => (
                        <li key={teacher.user.fullName}>{teacher.user.fullName}</li>
                    ))}
                </ul>
            )
        );
    };

    return (
        <GqlTable
            {...props}
            saveState={false}
            render={props.render || (props.checkTimepoints ? customRenderCheckTimepointDate : null)}
            renders={{
                ColorByCategory,
                ColorByMSCategory,
                IneligibleScore,
                Status,
                UploadImage: UploadImage(),
                Image,
                Audio,
                PercentCompleted,
                CheckIsTimepointAssigned,
                InstructorList,
                UpperCase,
                Grade,
                ...props.renders,
            }}
        />
    );
};

export default ScoresTable;
