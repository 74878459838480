import React from 'react';
import style from './style.css';
import PropTypes from 'prop-types';
import { D3vis } from 'cccisd-laravel-peer-noms';
import _get from 'lodash/get';
import Loader from 'cccisd-loader';

const nomlist = [
    'likemost',
    'likeleast',
    'friendly',
    'helpful',
    'fight',
    'mean',
    'alone',
    'leftout',
    'shy',
    'teased',
    'friends',
    'spendtime',
    'caring',
    'understanding',
    'compassionate',
];

export default class Viz extends React.Component {
    static propTypes = {
        timepoint: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        studentData: PropTypes.array,
        isDemographicFilter: PropTypes.bool,
    };

    state = {
        students: [],
        nominations: [],
        rerendering: false, // <-- D3Viz is mostly vanilla javascript, so needs to be unmounted/mounted to re-render
    };

    componentDidMount() {
        this.setStudentsAndNoms();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.studentData !== this.props.studentData) {
            this.setStudentsAndNoms();
        }
    }

    setStudentsAndNoms = () => {
        this.setState({ rerendering: true }, async () => {
            const students = this.processStudents();
            const nominations = this.processNominations();
            this.setState({ students, nominations, rerendering: false });
        });
    };

    processStudents = () => {
        let { studentData } = this.props;
        let pnLockWhiteList;
        try {
            pnLockWhiteList = JSON.parse(_get(studentData, '[0].parentGroup.class.fields.pn_locks')).find(
                pnLock => pnLock.timepoint === this.props.timepoint
            ).locked;
        } catch (e) {
            pnLockWhiteList = undefined;
        }

        const studentList = studentData
            .filter(student => {
                return pnLockWhiteList && pnLockWhiteList.includes(student.pawn.pawnId);
            })
            .map(student => ({
                id: student.pawn.pawnId,
                label: student.user.fullName || student.fields.studentId,
                id_label: student.fields.studentId,
                gender: String(student.fields.gender),
                ethnicity: String(student.fields.ethnicity),
                frpl: String(student.fields.frpl),
                ell: String(student.fields.ell),
                score: 100, // no EE scoring
            }));

        return studentList;
    };

    processNominations = () => {
        let { studentData } = this.props;
        let noms = [];
        studentData.forEach(student => {
            const nominator = parseInt(student.pawn.pawnId, 10);
            nomlist.forEach(nomType => {
                // parse raw data "[1, 2]", make nomination for both 1 and 2 separately
                let nominatedList = _get(student, `pnscores.devTags[pn_${nomType}_raw]`, '') || '';
                nominatedList = nominatedList.replace(/[[\]]*/g, '').split(',');
                nominatedList.forEach(nominee => {
                    if (nominee) {
                        nominee = parseInt(nominee, 10);
                        const nomIndex = noms.findIndex(nom => nom.nominator === nominator && nom.nominee === nominee);
                        // if nominator/nominee relations already exists, just bump that nomType in existing nom
                        if (nomIndex >= 0) {
                            noms[nomIndex][nomType] = 1;
                        }
                        // else make a new entry in noms for that relation
                        else {
                            const newNom = { nominator, nominee };
                            nomlist.forEach(nomKey => {
                                newNom[nomKey] = 0;
                            });
                            newNom[nomType] = 1;
                            noms.push(newNom);
                        }
                    }
                });
            });
        });
        return noms;
    };

    render() {
        let { students, nominations, rerendering } = this.state;
        return (
            <div className={style.vizWrapper}>
                {rerendering && (
                    <div className={style.vizEmptyContainer}>
                        <Loader loading type="inline" />
                    </div>
                )}
                {!rerendering && students.length === 0 && (
                    <p className={style.vizEmptyContainer}>No eligible scores for time {this.props.timepoint}.</p>
                )}
                {!rerendering && students.length > 0 && nominations.length > 0 && (
                    <D3vis
                        students={students}
                        nominations={nominations}
                        spread={-30}
                        enableColors={this.props.isDemographicFilter}
                    />
                )}
            </div>
        );
    }
}
