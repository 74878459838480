import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';

/** Yes/No Toggle component */
export default class CustomCccisdToggle extends React.Component {
    static propTypes = {
        value: PropTypes.bool,
        onChange: PropTypes.func,
        label: PropTypes.node,
        disabled: PropTypes.bool,
        name: PropTypes.string,
    };

    static defaultProps = {
        onChange: () => {},
    };

    onChange = e => {
        if (this.props.disabled) {
            return;
        }

        this.props.onChange(e);
    };

    render() {
        const value = !!this.props.value;

        return (
            <div className={style.wrapper + ' ' + (this.props.disabled ? style.disabled : '')}>
                <label>
                    <div className={style.toggle}>
                        <input
                            type="checkbox"
                            checked={value}
                            value={value}
                            name={this.props.name}
                            onChange={this.onChange}
                        />
                        <span className={style.slider}>
                            <span className={style.circle} />
                        </span>
                    </div>
                    {this.props.label && <div className={style.label}>{this.props.label}</div>}
                </label>
            </div>
        );
    }
}
