import React from 'react';
import { LoginForm } from 'cccisd-laravel-nexus';
import { Link } from 'cccisd-react-router';
import { getHomeRoute } from '../../helpers';
import Style from './style.css';
import { Html } from 'cccisd-wysiwyg';
var AppDefs = window.cccisd.appDefs;

var Fortress = window.cccisd.fortress;

const Welcome = () => {
    const homepageRoute = getHomeRoute();

    return (
        <div className="landingPage" style={{ marginTop: '20px' }}>
            <div className="welcomeLogin">
                <div className="row">
                    <div className="col-md-8">
                        <div>
                            <h1>
                                <b>Welcome to Glencoe Networker</b>
                            </h1>
                        </div>
                        <div className={Style.welcomeText}>
                            <p>
                                <Html content={AppDefs.app.siteContent.landingPage} />
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        {Fortress.auth() ? (
                            <div className={Style.welcomeBox}>
                                <p>Hello, {Fortress.user.username}</p>
                                {homepageRoute && (
                                    <Link to={homepageRoute}>
                                        <button type="button" className="btn btn-primary">
                                            Go To Home
                                        </button>
                                    </Link>
                                )}
                            </div>
                        ) : (
                            <LoginForm showPasswordToggle />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Welcome;
