/*
 * Displays the appropriate assignment plan label for the student/teacher
 *
 * If the student belongs to a class or teacher belongs to a school that explicitly has an assignment plan set
 * then use that and indicate visually that it's a deviation from the district default
 *
 * otherwise fall back to the district default for either teacher or the student's grade
 *
 * failing all of that, return blank/null
 */
export default settings => {
    return ({ value, row, loadData }) => {
        // if the student has a selected assignment plan
        // i.e. if the class that the student belongs to explicitly has an assignment plan set
        //
        // also indicate that this is a deviation from the district default
        // probably want something better than an asterisk for this, but it's quick and easy
        if (value !== null) {
            return value + ' *';
        }

        let grade = null;
        if (settings.role === 'instructor') {
            grade = 'teacher';
        } else {
            grade = row['fields.grade'];
        }

        // Kindergarten grade is stored in appdefs as K, but in assignment plan list as "plan_0"
        if (grade === 'k' || grade === 'K') {
            grade = '0';
        }

        let gradeKey = 'plan_' + grade;
        let defaultAssignmentPlanId =
            row['ancestorGroups.groupingUnit.group.settings.defaultAssignmentPlans.' + gradeKey];

        // if ancestor district does not have default assignment plans defined, return null (current value)
        if (typeof defaultAssignmentPlanId === 'undefined') {
            return value;
        }

        const assignmentPlanList = row['ancestorGroups.groupingUnit.availableAssignmentPlanList'];
        // if ancestor district does not have any assignment plans yet, return null (current value)
        if (typeof assignmentPlanList === 'undefined') {
            return value;
        }

        let plan = assignmentPlanList.find(obj => {
            return String(obj.assignmentPlanId) == String(defaultAssignmentPlanId); //eslint-disable-line
        });

        if (typeof plan === 'object') {
            return plan.label;
        }

        return value;
    };
};
