import React, { useState, useEffect } from 'react';
import _set from 'lodash/set';
import _get from 'lodash/get';
import { client } from 'cccisd-apollo';
import axios from 'cccisd-axios';
import Button from 'cccisd-click-button';
import { Formik, Form, Field } from 'cccisd-formik';
import Loader from 'cccisd-loader';
import notification from 'cccisd-notification';
import assignmentPlansQuery from './assignmentPlans.graphql';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const Boilerplate = window.cccisd.boilerplate;

const DefaultAssignmentPlanForm = props => {
    const { row, closeModal, loadData } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [availableStudentPlans, setAvailableStudentPlans] = useState(null);

    function processPlans(planArr) {
        const formatted = planArr.map(plan => ({
            value: plan.assignmentPlanId,
            name: plan.label,
            sessionList: plan.sessionList,
        }));
        formatted.unshift({ value: '', name: '-- none --' });
        return formatted;
    }

    useEffect(() => {
        async function getAvailablePlans() {
            setIsLoading(true);
            const resp = await client.query({
                query: assignmentPlansQuery,
                variables: {
                    groupId: row['group.groupId'],
                },
                fetchPolicy: 'network-only',
            });

            const studentPlans = _get(resp, 'data.groups.groupingUnit.studentPlans', []) || [];

            setAvailableStudentPlans(processPlans(studentPlans));
            setIsLoading(false);
        }
        getAvailablePlans();
    }, []);

    async function onSubmit(values) {
        const resp = await axios.put(Boilerplate.route('api.nexus.group.update', { group: row['group.groupId'] }), {
            settings: values,
        });

        if (_get(resp, 'data.status', null) === 'success') {
            notification({
                message: 'Default Assignment Plans saved successfully!',
                type: 'success',
            });
            closeModal();
            loadData();
        } else {
            const errors = _get(resp, 'data.errors', {}) || {};
            notification({
                message: 'Failed to save settings',
                data: errors,
                duration: 0,
                type: 'danger',
            });
        }
    }

    function validate({ defaultAssignmentPlans }) {
        let errors = {};

        return errors;
    }

    // make sure all settings keys get sent with request body, because backend route
    // might overwrite the entire $settings column instead of merging with existing keys
    const savedSettings = {};
    const savedSettingsKeys = Object.keys(row).filter(k => k !== 'group.settings' && k.startsWith('group.settings'));
    savedSettingsKeys.forEach(fullKey => {
        const nestedKey = fullKey.replace('group.settings.', '');
        _set(savedSettings, nestedKey, row[fullKey]);
    });

    const grades = Appdefs.pawn.fields.find(f => f.handle === 'grade').values;
    const initialPlans = {};
    // previx each grade with "plan_" because Formik tries to convert
    // integer field names to an array
    grades.forEach(g => {
        initialPlans['plan_' + g.value] = '';
    });

    const initialValues = {
        defaultAssignmentPlans: initialPlans,
        ...savedSettings,
    };

    if (isLoading || !availableStudentPlans) {
        return (
            <div className={style.loader}>
                <Loader type="inline" loading />
            </div>
        );
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
            {({ isSubmitting, handleSubmit, isValid }) => (
                <Form>
                    <div className={style.columnLabels}>
                        <h5>Grade</h5>
                        <h5>Default Assignment Plan</h5>
                    </div>
                    {[...grades].map(({ name, value }) => {
                        const fieldName = `defaultAssignmentPlans.plan_${value}`;
                        return (
                            <Field name={fieldName} key={value}>
                                {fieldProps => {
                                    const options = availableStudentPlans;
                                    const errMsg = _get(fieldProps.form.errors, fieldName, '');

                                    return (
                                        <>
                                            <div
                                                className={'form-group ' + (errMsg ? 'has-error' : '')}
                                                style={{ marginBottom: errMsg ? '5px' : '15px' }}
                                            >
                                                <label
                                                    className={'control-label ' + style.gradeLabel}
                                                    htmlFor={`defaultAssignmentPlans.${value}`}
                                                >
                                                    {name}:
                                                </label>
                                                <select
                                                    className={'form-control ' + style.gradeSelect}
                                                    {...{
                                                        ...fieldProps.field,
                                                        value: fieldProps.field.value || '', // suppress warning for null value
                                                    }}
                                                >
                                                    {options.map(opt => (
                                                        <option value={opt.value} key={opt.value}>
                                                            {opt.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {errMsg && <p className={'text-danger ' + style.errMsg}>{errMsg}</p>}
                                        </>
                                    );
                                }}
                            </Field>
                        );
                    })}
                    <div className={style.submit}>
                        <Button
                            isLoading={isSubmitting}
                            title="Save"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                            isDisabled={!isValid}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default DefaultAssignmentPlanForm;
