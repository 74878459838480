import React from 'react';
import _get from 'lodash/get';

import { client, gql } from 'cccisd-apollo';
import Tabs from 'cccisd-tabs';
import Loader from 'cccisd-loader';
import defaultRenders from 'cccisd-table-renders';

import PeerNom from '../PeerNom';
import Table from '../ScoresTable';
import UploadImage from '../../renders/UploadImage';
import style from './style.css';

const Fortress = window.cccisd && window.cccisd.fortress;
const STATUS_MAP = ['Not Started', 'In Progress', 'Completed'];
const { Audio, Image } = defaultRenders.html;

export default class Administrative extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            tabs: [],
            selectedStudentTimepoint: '1',
            selectedTeacherTimepoint: '1',
            studentView: 'studentsOverall',
            teacherView: 'teacherProgress',
        };
        this.groupId = Fortress.user.acting.group_id;
        this.actingHandle = Fortress.user.acting.role.handle;
    }

    static defaultProps = {
        studentViews: [],
        teacherViews: [],
    };

    componentDidMount() {
        this.processTabs();
    }

    componentDidUpdate(prevProps) {
        // teachers only, forces processTabs to run
        // when a different class is selected
        if (prevProps.classId && prevProps.classId !== this.props.classId) {
            this.processTabs();
        }
    }

    onClickStudentTimepoint = selectedStudentTimepoint => {
        if (selectedStudentTimepoint === this.state.selectedStudentTimepoint) {
            return;
        }
        this.setState({ selectedStudentTimepoint }, () => {
            this.processTabs();
        });
    };

    onClickTeacherTimepoint = selectedTeacherTimepoint => {
        if (selectedTeacherTimepoint === this.state.selectedTeacherTimepoint) {
            return;
        }
        this.setState({ selectedTeacherTimepoint }, () => {
            this.processTabs();
        });
    };

    processTabs = async () => {
        const { assignmentPlanQuery, archivedReportFileList } = this.props;
        const { selectedStudentTimepoint, selectedTeacherTimepoint } = this.state;

        const response = await client.query({
            query: gql`
                ${assignmentPlanQuery}
            `,
            variables: {
                classId: this.props.classId, // teachers only
            },
            // teachers need query to re-run every time they select different class
            fetchPolicy: this.actingHandle === 'instructor' ? 'network-only' : 'cache-first',
        });

        const studentPlanList = _get(response, 'data.groups.groupingUnit.learnerPlanList', []);
        const teacherPlanList = [];

        /*
            {
                <district groupId>: {
                    plan_0: <assignment plan id>
                    ...
                }
            }
        */
        let defaultAssignmentPlans;
        if (this.actingHandle === 'orgAdmin') {
            const guList = _get(response, 'data.groups.groupingUnitList', []) || [];
            defaultAssignmentPlans = guList.reduce((total, currGu) => {
                return {
                    ...total,
                    [currGu.group.groupId]: _get(currGu, 'group.settings.defaultAssignmentPlans', {}) || {},
                };
            }, {});
        } else {
            const gu = _get(response, 'data.groups.groupingUnit');
            defaultAssignmentPlans = {
                [gu.group.groupId]: _get(gu, 'group.settings.defaultAssignmentPlans', {}) || {},
            };
        }

        const classList = _get(response, 'data.groups.classList', []) || [];
        const siteList = _get(response, 'data.groups.siteList', []) || [];
        const surveyList = _get(response, 'data.flows.surveyList', []) || [];
        const reportData = _get(response, 'data.resources', {});
        const blockScoreAccess = _get(response, 'data.roles.anyRole.fields.blockScoreAccess', false);
        const studentInfo = this.processStudentPlans(studentPlanList, defaultAssignmentPlans, classList);

        const studentTimepoints = studentInfo.timepoints;
        const teacherInfo = this.processTeacherPlans(teacherPlanList, surveyList, siteList, defaultAssignmentPlans);

        const studentTimepoint = studentTimepoints.size === 1 ? [...studentTimepoints][0] : selectedStudentTimepoint;

        let tabs = [this.processStudentTab(studentInfo, studentTimepoint)];

        if (!blockScoreAccess) {
            tabs = [...tabs, ...this.processScoringTabs(studentInfo, studentTimepoint)];
        }

        if (teacherInfo.deploymentIds.length > 0 && this.actingHandle !== 'instructor') {
            tabs = [...tabs, this.processTeacherTab(teacherInfo, selectedTeacherTimepoint)];
        }

        let fileList = Array.isArray(reportData.fileList) ? reportData.fileList : [];
        if (fileList.length && Array.isArray(archivedReportFileList)) {
            archivedReportFileList.forEach(file => {
                if (!fileList.some(f => f.folder.path === file.folder.path && f.filename === file.filename)) {
                    fileList.push(file);
                }
            });
        }

        this.setState({
            tabs,
            selectedStudentTimepoint: studentTimepoint,
            isLoading: false,
        });
    };

    onChangeStudentView = event => {
        this.setState({ studentView: event.target.value }, () => {
            this.processTabs();
        });
    };

    onChangeTeacherView = event => {
        this.setState({ teacherView: event.target.value }, () => {
            this.processTabs();
        });
    };

    processStudentTab = (studentInfo, timepoint) => {
        const { studentView } = this.state;
        const { studentViews, classId } = this.props;

        const options = studentViews.map(view => (
            <option value={view.handle} key={view.handle}>
                {view.label}
            </option>
        ));
        const isNetworkerTab = studentInfo.tabs.pn.timepoints.size > 0;

        // classId is undefined for non-teacher roles
        if (classId === '') {
            return {
                name: 'Students',
                title: 'Students',
                content: (
                    <p>
                        This teacher account is not linked to any current-year classes.
                        <br />
                        <br />
                        Please contact your administrator for assistance.
                    </p>
                ),
            };
        }

        let tableProps = this.props[studentView];

        if (studentView === 'studentsOverall') {
            if (isNetworkerTab) {
                if (!tableProps.columns.find(column => column.handle === 'audio')) {
                    tableProps.columns.push({
                        handle: 'audio',
                        label: 'Audio',
                        name: 'fields.pn_audio_url',
                        render: renderProps => {
                            if (studentInfo.tabs.pn.students.includes(renderProps.row['pawn.pawnId'])) {
                                return Audio({ audioUrlPath: 'fields.pn_audio_url' })(renderProps);
                            }
                        },
                        sort: true,
                        filter: false,
                    });
                    tableProps.columns.push({
                        handle: 'image',
                        label: 'Image',
                        name: 'fields.pn_image_url',
                        render: renderProps => {
                            if (studentInfo.tabs.pn.students.includes(renderProps.row['pawn.pawnId'])) {
                                return Image({ imageUrlPath: 'fields.pn_image_url' })(renderProps);
                            }
                        },
                        sort: true,
                        filter: false,
                    });
                    tableProps.columns.push({
                        handle: 'imageUpload',
                        label: 'Upload',
                        name: 'imageUpload',
                        render: renderProps => {
                            if (studentInfo.tabs.pn.students.includes(renderProps.row['pawn.pawnId'])) {
                                return UploadImage()(renderProps);
                            }
                        },
                        sort: false,
                        filter: false,
                    });
                }
            } else {
                tableProps.columns = tableProps.columns.filter(
                    column => !['audio', 'image', 'imageUpload'].includes(column.handle)
                );
            }
        }

        tableProps.columns.forEach(col => {
            col.setStyle = (value, row) =>
                classId && row['group.groupId'] === classId ? { backgroundColor: 'rgb(255, 255, 220)' } : {};
            if (col.handle === 'status') {
                col.label = `Time ${timepoint}: Status`;
            }
            if (col.handle === 'started') {
                col.label = `Time ${timepoint}: Started`;
            }
            if (col.handle === 'completed') {
                col.label = `Time ${timepoint}: Completed`;
            }
            if (col.handle === 'percent') {
                col.label = `Time ${timepoint}: % Completed`;
                col.sort = false;
            }
        });

        return {
            name: 'Students',
            title: 'Students',
            content: (
                <div>
                    <div className={style.controlContainer}>
                        {this.renderTimepointPills(studentInfo.timepoints, timepoint, this.onClickStudentTimepoint)}
                        <div className={style.viewSelect}>
                            <select
                                name="studentView"
                                id="studentView"
                                value={studentView}
                                onChange={this.onChangeStudentView}
                                className={style.studentSelect}
                            >
                                {options}
                            </select>
                        </div>
                    </div>
                    <Table
                        key={studentView}
                        {...tableProps}
                        graphqlVariables={{ timepoint, classId }}
                        noRecordsMessage="No records to display."
                    />
                </div>
            ),
        };
    };

    processScoringTabs = (studentInfo, timepoint) => {
        const { selwebTabPropsPN, classId } = this.props;

        const tabNameMapper = {
            pn: 'Networker',
        };
        const glossaryMapper = {
            pn: <></>,
        };

        const studentTabs = studentInfo.tabs;
        const studentTimepoints = studentInfo.timepoints;

        const tabPropsMapper = {
            pn: selwebTabPropsPN,
        };

        return Object.keys(studentTabs)
            .map(tab => {
                // Project Admin never have Peer Noms tab
                if (tab === 'pn' && this.actingHandle === 'orgAdmin') {
                    return null;
                }

                return (
                    studentTabs[tab].timepoints.size > 0 && {
                        name: tab,
                        title: tabNameMapper[tab],
                        content: (
                            <div>
                                {this.renderTimepointPills(studentTimepoints, timepoint, this.onClickStudentTimepoint)}
                                {studentTabs[tab].timepoints.has(timepoint) ? (
                                    <div>
                                        {tab === 'pn' ? (
                                            <PeerNom
                                                tableProps={tabPropsMapper[tab]}
                                                selectedClass={classId}
                                                assignmentId={studentTabs[tab].assignmentId}
                                                timepoint={timepoint}
                                            />
                                        ) : (
                                            <>
                                                {glossaryMapper[tab]}
                                                <Table
                                                    key={tab}
                                                    checkTimepoints
                                                    selectedStudentTimepoint={timepoint}
                                                    {...tabPropsMapper[tab]}
                                                    graphqlVariables={{
                                                        groupId: this.groupId,
                                                        timepoint,
                                                        classId,
                                                        classIds: studentTabs[tab].classes,
                                                        studentIds: studentTabs[tab].students,
                                                        assignmentId: studentTabs[tab].assignmentId,
                                                    }}
                                                    noRecordsMessage="No students to display."
                                                />
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <p>No assignment for this timepoint.</p>
                                )}
                            </div>
                        ),
                    }
                );
            })
            .filter(tab => tab); // removes 'undefined' tabs
    };

    processTeacherTab = (teacherInfo, timepoint) => {
        const { teacherView } = this.state;
        const { teacherViews } = this.props;

        // deep copy tableProps so we only add extra columns once
        let tableProps = JSON.parse(JSON.stringify(this.props[teacherView]));

        let options = teacherViews.map(view => (
            <option value={view.handle} key={view.handle}>
                {view.label}
            </option>
        ));

        if (teacherView === 'teacherProgress') {
            teacherInfo.surveyHandles.forEach(survey => {
                tableProps.columns.push({
                    handle: survey,
                    label: teacherInfo.surveyLabels[survey],
                    name: 'surveyProgressStatus.' + survey,
                    render: ({ value, row }) => {
                        return <div>{STATUS_MAP[Number.parseInt(value, 10)]}</div>;
                    },
                    sort: true,
                    filter: true,
                    filterSettings: {
                        type: 'selectbox',
                        options: [
                            {
                                label: 'Not Started',
                                value: '0',
                            },
                            {
                                label: 'In Progress',
                                value: '1',
                            },
                            {
                                label: 'Complete',
                                value: '2',
                            },
                        ],
                    },
                });
            });
        }
        const tables = (
            <Table
                key={teacherView}
                {...tableProps}
                graphqlVariables={{
                    timepoint,
                    deploymentId: teacherInfo.deploymentIds[timepoint],
                }}
                noRecordsMessage="No records to display."
            />
        );

        return {
            name: 'Teachers',
            title: 'Teachers',
            content: (
                <div>
                    <div className={style.controlContainer}>
                        {this.renderTimepointPills(teacherInfo.timepoints, timepoint, this.onClickTeacherTimepoint)}
                        {teacherViews.length > 1 ? (
                            <div className={style.viewSelect}>
                                <select
                                    name="teacherView"
                                    id="teacherView"
                                    value={teacherView}
                                    onChange={this.onChangeTeacherView}
                                    className={style.teacherSelect}
                                >
                                    {options}
                                </select>
                            </div>
                        ) : null}
                    </div>
                    <div>{tables}</div>
                </div>
            ),
        };
    };

    processStudentTimepoints = learnerPlans => {
        let timepoints = new Set();

        learnerPlans.forEach(plan => {
            plan.sessionList.forEach(session => {
                let timepoint = session.timepoint || null;
                if (!timepoint) {
                    return;
                }

                timepoints.add(timepoint);
            });
        });

        return timepoints;
    };

    processStudentPlans = (learnerPlans, defaultAssignmentPlans, classList) => {
        const { classId } = this.props;

        let result = {
            timepoints: new Set(),
            tabs: {
                pn: {},
            },
        };

        Object.keys(result.tabs).forEach(key => {
            result.tabs[key] = {
                timepoints: new Set(),
                assignmentId: null,
                classes: [],
                students: [],
            };
        });

        /* {
            <Class groupId>: {
                grades: {
                    "<grade>": [<pawnId>]
                },
                isPlanSelected: <boolean>
            },
            ...
        }
        */
        const classInfoMapper = {};
        classList.forEach(c => {
            const groupId = c?.group?.groupId;
            if (!groupId) {
                return;
            }

            classInfoMapper[groupId] = {};
            classInfoMapper[groupId].isPlanSelected = !!c?.selectedAssignmentPlan?.assignmentPlanId;

            classInfoMapper[groupId].grades = {};
            (c?.childRoles?.learnerList || []).forEach(student => {
                const grade = student?.fields?.grade;
                if (!grade) {
                    return;
                }

                if (grade in classInfoMapper[groupId].grades) {
                    classInfoMapper[groupId].grades[grade].push(student.pawn.pawnId);
                } else {
                    classInfoMapper[groupId].grades[grade] = [student.pawn.pawnId];
                }
            });
        });

        learnerPlans.forEach(plan => {
            let pnPicked = false;

            let gradesWithPlanAsDefault = [];
            Object.values(defaultAssignmentPlans).forEach(currDistrictDefaults => {
                Object.keys(currDistrictDefaults).forEach(gradeKey => {
                    const planId = currDistrictDefaults[gradeKey];
                    if (
                        planId &&
                        parseInt(planId, 10) === parseInt(plan.assignmentPlanId, 10) &&
                        !gradesWithPlanAsDefault.includes(gradeKey)
                    ) {
                        gradesWithPlanAsDefault.push(gradeKey);
                    }
                });
            });
            gradesWithPlanAsDefault = gradesWithPlanAsDefault.map(gradeKey => gradeKey.replace('plan_', ''));

            if (plan.selectedByGroupList.length === 0 && gradesWithPlanAsDefault.length === 0) {
                // Skip if not used in any classes or District default
                return;
            }

            // Teachers can only look at one class at a time (classId)
            //  other roles see mix of all classes
            let classesUsingPlanAsDefault = [];
            if (gradesWithPlanAsDefault.length > 0) {
                Object.keys(classInfoMapper).forEach(groupId => {
                    const { grades, isPlanSelected } = classInfoMapper[groupId];
                    if (isPlanSelected) {
                        return;
                    }

                    const isClassUsingPlan = Object.keys(grades).some(
                        grade =>
                            Array.isArray(grades[grade]) &&
                            grades[grade].length > 0 &&
                            gradesWithPlanAsDefault.includes(grade)
                    );

                    if (isClassUsingPlan) {
                        classesUsingPlanAsDefault.push(parseInt(groupId, 10));
                    }
                });
            }

            let classesUsingPlan = [
                ...classesUsingPlanAsDefault,
                ...plan.selectedByGroupList.map(clss => parseInt(clss.group.groupId, 10)),
            ];

            if (classId) {
                classesUsingPlan = classesUsingPlan.filter(groupId => groupId === classId);
            }

            if (classesUsingPlan.length <= 0) {
                return;
            }

            plan.sessionList.forEach(session => {
                let timepoint = _get(session, 'timepoint', null);
                if (!timepoint) {
                    return;
                }
                let assignmentId = _get(session, 'assignmentId', null);
                let surveyHandles = _get(session, 'settings.options', []) || [];

                result.timepoints.add(timepoint);

                surveyHandles.forEach(handle => {
                    if (handle.startsWith('pn_')) {
                        result.tabs.pn.timepoints.add(timepoint);
                        result.tabs.pn.assignmentId = assignmentId;
                        pnPicked = true;
                    }
                });
            });

            classesUsingPlan.forEach(groupId => {
                const classInfo = classInfoMapper[groupId];

                function addStudentsToResult(tabKey) {
                    if (!classInfo) {
                        return;
                    }

                    // add all learners
                    if (classInfo.isPlanSelected) {
                        Object.values(classInfo.grades).forEach(arr => {
                            if (!Array.isArray(arr)) {
                                return;
                            }

                            arr.forEach(studentPawnId => {
                                result.tabs[tabKey].students.push(studentPawnId);
                            });
                        });
                    }

                    // add only learners with plan as default
                    else {
                        gradesWithPlanAsDefault.forEach(g => {
                            if (!(g in classInfo.grades) || !Array.isArray(classInfo.grades[g])) {
                                return;
                            }

                            classInfo.grades[g].forEach(studentPawnId => {
                                result.tabs[tabKey].students.push(studentPawnId);
                            });
                        });
                    }
                }

                if (pnPicked) {
                    result.tabs.pn.classes.push(groupId);
                    addStudentsToResult('pn');
                }
            });
        });
        return result;
    };

    processTeacherPlans = (instructorPlans, surveyList, siteList, defaultAssignmentPlans) => {
        const defaultPlanId = defaultAssignmentPlans.plan_teacher;
        const isDefaultUsed = defaultPlanId && siteList.some(site => !site?.selectedAssignmentPlan?.assignmentPlanId);

        let result = {
            assignmentIds: new Set(),
            deploymentIds: [],
            timepoints: new Set(),
            projectIds: new Set(),
            surveyHandles: new Set(),
            surveyLabels: [],
            deployments: [],
        };

        instructorPlans.forEach(plan => {
            if (
                plan.selectedByGroupList.length === 0 &&
                (!isDefaultUsed || parseInt(plan.assignmentPlanId, 10) !== parseInt(defaultPlanId, 10))
            ) {
                // skip if not used in any schools
                return;
            }

            plan.sessionList.forEach(session => {
                let assignmentId = _get(session, 'assignmentId', null);
                let deploymentId = _get(session, 'deploymentId', null);
                let projectId = _get(session, 'projectId', null);
                let timepoint = _get(session, 'timepoint', null);

                if (!timepoint) {
                    return;
                }
                result.assignmentIds.add(assignmentId);
                result.deploymentIds[timepoint] = [deploymentId];
                result.timepoints.add(timepoint);
                result.projectIds.add(projectId);
                result.deployments[timepoint] = deploymentId;
                session.settings &&
                    session.settings.options &&
                    session.settings.options.forEach(survey => {
                        result.surveyHandles.add(survey);
                    });
            });
        });

        result.assignmentIds = Array.from(result.assignmentIds);
        result.projectIds = Array.from(result.projectIds);
        result.surveyHandles = Array.from(result.surveyHandles);

        surveyList.forEach(allSurvey => {
            result.surveyHandles.forEach(survey => {
                if (allSurvey.surveyHandle === survey) {
                    result.surveyLabels[survey] = allSurvey.label;
                }
            });
        });

        return result;
    };

    renderTimepointPills = (timepoints, selectedTimepoint, onClick) => {
        return (
            timepoints.size > 1 && (
                <div className={`btn-group ${style.pillButtons}`} role="group">
                    {[...timepoints]
                        .sort((a, b) => a - b)
                        .map(timepoint => (
                            <button
                                key={timepoint}
                                type="button"
                                className={`btn ${selectedTimepoint === timepoint ? 'btn-primary' : 'btn-default'}`}
                                onClick={() => onClick(timepoint)}
                            >
                                Time {timepoint}
                            </button>
                        ))}
                </div>
            )
        );
    };

    renderGlossaryButton = (type, name) => {
        return (
            <div className={style.glossaryContainer}>
                <button type="button" className="btn btn-primary" onClick={() => this.openGlossary(type)}>
                    {name} Glossary
                </button>
            </div>
        );
    };

    openGlossary = sessionType => {
        const linkMap = {
            hs: 'https://xsel-labs.com/wp-content/uploads/2021/02/report_appendix_mshs.pdf',
        };

        window.open(linkMap[sessionType], '_blank');
    };

    render() {
        // TO DO parse url params more officially
        let initialTab = this.props?.location?.search;
        if (initialTab) {
            initialTab = initialTab.replace('?initialTab=', '');
            if (!this.state.tabs.some(t => t.name === initialTab)) {
                initialTab = null;
            }
        }

        return (
            <Loader loading={this.state.isLoading}>
                {this.state.tabs.length > 0 && (
                    <div className={style.tabsContainer}>
                        <Tabs tabList={this.state.tabs} initialTab={initialTab} />
                    </div>
                )}
            </Loader>
        );
    }
}
