import PropTypes from 'prop-types';
import React from 'react';
import Confirm from 'cccisd-confirm';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import notification from 'cccisd-notification';
import Axios from 'cccisd-axios';
import Form from './form.js';
import Loader from 'cccisd-loader';
import { createStore, combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

const Boilerplate = window.cccisd.boilerplate;

const reducer = combineReducers({
    form: formReducer,
});

function configureStore(initialState) {
    return createStore(reducer, initialState);
}

const store = configureStore({});

const detectFile = value => {
    if (value.constructor === Array && value.length >= 1 && value[0] instanceof File) {
        return true;
    }
    return false;
};

export default class extends React.Component {
    static propTypes = {
        updateData: PropTypes.func,
        uploadRoute: PropTypes.string.isRequired,
        uploadTitle: PropTypes.string,
        deleteRoute: PropTypes.string.isRequired,
        deleteTitle: PropTypes.string,
        showDelete: PropTypes.bool,
        pawnId: PropTypes.number.isRequired,
        pawnHash: PropTypes.string.isRequired,
        imageUrl: PropTypes.string,
    };

    state = {
        loading: false,
    };

    getInitialState() {
        return {
            loading: false,
        };
    }

    _onSubmit = values => {
        let t = 'WYSIWYGImage';
        let s = 1;

        let v = {
            name: values.name,
            internal_name: '',
            description: '',
            search_terms: '',
            tags: [],
            thumbnail_option: '',
            thumbnails: undefined,
            images: values.image,
        };

        // createResource = (values, scope, type, cb)
        var fd = new FormData();
        Object.keys(v).forEach((key, index) => {
            if (v[key]) {
                if (detectFile(v[key])) {
                    v[key].forEach(file => {
                        fd.append(key + '[]', file);
                    });
                } else if (v[key].constructor === Array || typeof v[key] === 'object') {
                    fd.append(key, JSON.stringify(v[key]));
                } else {
                    fd.append(key, v[key]);
                }
            }
        });
        fd.append('type', t);
        fd.append('scope', s);
        fd.append('pawnId', this.props.pawnId);
        fd.append('pawnHash', this.props.pawnHash);

        this.setState({
            loading: true,
        });
        // save resource to resource storage
        Axios.post(Boilerplate.route(this.props.uploadRoute), fd).then(response => {
            this.setState({
                loading: false,
            });
            if (response.data.status !== 'success') {
                notification({
                    message: response.data.error,
                    type: 'danger',
                    duration: 8000,
                });
                response.data.errors &&
                    response.data.errors.forEach(e => {
                        console.warn(e);
                    });
            } else {
                notification({
                    message: 'Image added',
                    type: 'success',
                    duration: 8000,
                });
                if (this.props.updateData) {
                    this.props.updateData();
                }
                this.refs.modal.close();
            }
        });
    };

    confirmDeleteImage = () => {
        Confirm({
            message: 'Are you sure you want to delete the image?',
            description: 'You are about to delete this image',
            confirmLabel: 'Yes',
            abortLabel: 'No',
        })
            .then(() => {
                this.deleteImage();
            })
            .fail(() => {
                // do something when 'No' has been pressed
            });
    };

    deleteImage = () => {
        // save resource to resource storage
        var fd = new FormData();
        fd.append('pawnId', this.props.pawnId);
        fd.append('pawnHash', this.props.pawnHash);

        Axios.post(Boilerplate.route(this.props.deleteRoute), fd).then(response => {
            this.setState({
                loading: false,
            });
            if (response.data.status !== 'success') {
                notification({
                    message: response.data.error,
                    type: 'danger',
                    duration: 8000,
                });
                response.data.errors &&
                    response.data.errors.forEach(e => {
                        console.warn(e);
                    });
            } else {
                notification({
                    message: 'Image removed',
                    type: 'success',
                    duration: 8000,
                });
                if (this.props.updateData) {
                    this.props.updateData();
                }
            }
        });
    };

    render() {
        const uploadTitle = this.props.uploadTitle || 'Upload Image';
        const deleteTitle = this.props.deleteTitle || 'Remove Image';

        var uploadTrigger = (
            <Tooltip title={uploadTitle}>
                <button className="btn btn-success btn-xs" type="button">
                    <span className="glyphicon glyphicon-camera" />
                </button>
            </Tooltip>
        );
        var deleteTrigger = this.props.showDelete ? (
            <Tooltip title={deleteTitle}>
                <button
                    className="btn btn-danger btn-xs"
                    type="button"
                    onClick={this.confirmDeleteImage}
                    style={{ marginLeft: '2px' }}
                >
                    <span className="glyphicon glyphicon-remove" />
                </button>
            </Tooltip>
        ) : (
            ''
        );

        return (
            <div>
                <Modal trigger={uploadTrigger} title={uploadTitle} ref="modal">
                    <Loader loading={this.state.loading}>
                        <Form onSubmit={this._onSubmit} store={store} />
                    </Loader>
                </Modal>
                {deleteTrigger}
            </div>
        );
    }
}
