import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';
// import Footer from '../../components/Footer';
import Footer from 'cccisd-footer';
import { setInsecure } from '../../reducers/security.js';
import style from './style.css';

const AppDefs = window.cccisd.appDefs;
const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

class FluidLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        isFixed: PropTypes.bool,
        isInsecure: PropTypes.bool,
        className: PropTypes.string,
        showSecondaryNav: PropTypes.bool,
        showFooter: PropTypes.bool,

        // From redux
        isSecure: PropTypes.bool.isRequired,
        lastLogin: PropTypes.string.isRequired,
        setInsecure: PropTypes.func.isRequired,
    };

    static defaultProps = {
        className: 'container',
        showSecondaryNav: false,
        showFooter: true,
    };

    componentDidMount() {
        const { isInsecure, lastLogin, isSecure } = this.props;
        if (Fortress.user.actual.role.handle === 'guest') {
            return;
        }
        if (!isInsecure && lastLogin === Fortress.user.actual.user.last_login && !isSecure) {
            window.location = Boilerplate.url('/api/user/logout');
        }
        if (isInsecure) {
            this.props.setInsecure();
        }
    }

    render() {
        const { showSecondaryNav, className } = this.props;

        return (
            <div className={style.wrapper + (this.props.showSecondaryNav ? ' ' + style.secondaryNav : '')}>
                <div className={style.body}>
                    {showSecondaryNav && Fortress.auth() && (
                        <SecondaryNav className={className} navs={AppDefs.navs} routes={AppDefs.routes} />
                    )}
                    <div className={this.props.className}>{this.props.children}</div>
                </div>

                {this.props.showFooter && <Footer className={this.props.className} />}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isSecure: state.app.security.isSecure,
    lastLogin: state.app.security.lastLogin,
});

export default connect(mapStateToProps, { setInsecure })(FluidLayout);
