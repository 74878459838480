import ImageButton from './button.js';
import React from 'react';

/*
 * Displays the upload button
 *
 */
export default settings => {
    return ({ value, row, loadData }) => {
        const uploadTitle = 'Upload image for ' + row['user.firstName'] + ' ' + row['user.lastName'];
        const uploadRoute = 'api.pn.imageUpload';
        const deleteTitle = 'Delete image for ' + row['user.firstName'] + ' ' + row['user.lastName'];
        const deleteRoute = 'api.pn.imageDelete';
        const pawnId = row['pawn.pawnId'];
        const pawnHash = row['pawn.pawnHash'];
        const imageUrl = row['fields.pn_image_url'];
        const showDelete = !!row['fields.pn_image_url'];
        return (
            <ImageButton
                updateData={loadData}
                uploadTitle={uploadTitle}
                uploadRoute={uploadRoute}
                deleteTitle={deleteTitle}
                deleteRoute={deleteRoute}
                showDelete={showDelete}
                pawnId={pawnId}
                pawnHash={pawnHash}
                imageUrl={imageUrl}
            />
        );
    };
};
