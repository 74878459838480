import React from 'react';
import ClickButton from 'cccisd-click-button';
import Axios from 'cccisd-axios';
import Confirm from 'cccisd-confirm';

const Boilerplate = window.cccisd.boilerplate;

async function resetLock(row, timepoint, loadData) {
    try {
        await Confirm({
            message: 'Confirm Allowing Late Added Students To Appear in Peer Nominations',
            description: `By clicking "Unlock Class Anyway", you confirm that you want late-added students to appear in the list of students to nominate, even though data collection has already started. This risks data integrity problems related to the available selections being inconsistent from student to student.`,
            confirmLabel: 'Unlock Class Anyway',
            abortLabel: 'Cancel',
        });
    } catch (e) {
        return;
    }
    var fd = new FormData();
    fd.append('groupId', row['group.groupId']);
    fd.append('timepoint', timepoint);
    Axios.post(Boilerplate.route('api.pn.reset'), fd).then(response => {
        loadData();
    });
}

/*
 * Displays the student launcher button or status, depending on conditions
 */
export default settings => {
    return ({ value, row, loadData }) => {
        let lockCount = 0;
        const pnLocks = JSON.parse(value);
        if (pnLocks) {
            const thisLock = pnLocks.find(record => record.timepoint === settings.timepoint);
            if (thisLock && thisLock.locked) {
                lockCount = thisLock.locked.length;
            }
        }
        const isLocked = lockCount > 0;
        if (isLocked) {
            return (
                <div style={{ whiteSpace: 'nowrap' }}>
                    <div>
                        {lockCount}
                        <ClickButton
                            className="btn btn-primary btn-xs"
                            style={{ marginLeft: '10px' }}
                            title="Unlock"
                            onClick={resetLock.bind(this, row, settings.timepoint, loadData)}
                        />
                    </div>
                </div>
            );
        }
        return (
            <div style={{ whiteSpace: 'nowrap' }}>
                <div>0</div>
            </div>
        );
    };
};
