import PropTypes from 'prop-types';
import React from 'react';
import ClickButton from 'cccisd-click-button';
import { reduxForm } from 'redux-form';
import { FieldBlock } from 'cccisd-redux-form-helpers';
import Dropzone from 'react-dropzone';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _isEmpty from 'lodash/isEmpty';

export const fields = ['image'];

const validate = (values, props) => {
    const errors = {};

    if (_isEmpty(values.image)) {
        errors.image = 'Image is required';
    }

    if (!_isEmpty(values.image) && values.image.length > 1) {
        errors.image = 'Only one image is allowed';
    }

    return errors;
};

class DropZoneHelper extends React.Component {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        value: PropTypes.array,
    };

    onDrop = (filesToUpload, e) => {
        this.props.onChange(filesToUpload);
    };

    onClick = file => {
        // check what name property exists
        let nameProp;
        let name;
        if (file.name_original) {
            nameProp = 'name_original';
            name = file.name_original;
        } else {
            nameProp = 'name';
            name = file.name;
        }

        // remove
        var newValue = [...this.props.value];
        if (_find(newValue, o => o[nameProp] === name)) {
            _remove(newValue, n => n[nameProp] === name);
            this.props.onChange(newValue);
        }
    };

    render() {
        const { value } = this.props;

        let fileContent;
        if (value && value.length) {
            let filename;
            if (value[0].name_original) {
                filename = value[0].name_original;
            } else {
                filename = value[0].name;
            }

            let theFile;
            if (value[0].preview) {
                theFile = value[0].preview;
            } else if (value[0].uri) {
                theFile = value[0].uri;
            } else {
                theFile = '';
            }

            let filenameStyle = {
                marginTop: '15px',
            };

            // not a good practice
            fileContent = (
                <div style={{ padding: '10px' }}>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <div className="doc" style={{ display: 'flex', alignItems: 'center' }}>
                                {theFile && (
                                    <img
                                        src={theFile}
                                        style={{
                                            width: '80px',
                                            border: '1px solid black',
                                            marginRight: '10px',
                                        }}
                                        alt="Preview"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="filename" style={filenameStyle}>
                            {filename}{' '}
                            <button
                                onClick={this.onClick.bind(null, value[0])}
                                className="btn btn-danger"
                                type="button"
                                style={{
                                    color: '#d9534f',
                                    borderColor: '#fff',
                                    backgroundColor: '#fff',
                                    padding: '0',
                                    fontSize: '12px',
                                }}
                            >
                                <span className="glyphicon glyphicon-remove" />
                            </button>
                        </div>
                    </div>
                </div>
            );
        } else {
            fileContent = (
                <Dropzone
                    style={{
                        height: '100px',
                        borderWidth: '2px',
                        borderColor: '#666',
                        borderStyle: 'dashed',
                        borderRadius: '5px',
                    }}
                    name="Image"
                    onDrop={this.onDrop}
                >
                    <div style={{ padding: '10px' }}>
                        Try dropping an image here, or click to select an image to upload.
                    </div>
                </Dropzone>
            );
        }
        return <div>{fileContent}</div>;
    }
}

class Form extends React.Component {
    static propTypes = {
        fields: PropTypes.object,
        handleSubmit: PropTypes.func,
    };

    onDZchange = filesToUpload => {
        //        if (_isEmpty(filesToUpload)) {
        //      this.props.fields.name.onChange('');
        //  } else {
        //     this.props.fields.name.onChange(filesToUpload[0].name);
        // }
    };

    render() {
        const {
            fields: { image },
            handleSubmit,
        } = this.props;
        let tempVal = image.value === '' ? undefined : image.value;
        return (
            <form onSubmit={handleSubmit}>
                <FieldBlock field={image} label="Image:">
                    <DropZoneHelper onChange={image.onChange} value={tempVal} />
                </FieldBlock>
                <div style={{ display: 'table', width: '100%' }}>
                    <div style={{ float: 'right' }}>
                        <ClickButton className="btn btn-primary" title="Submit" onClick={handleSubmit} />
                    </div>
                </div>
            </form>
        );
    }
}

export default reduxForm({
    form: 'ImageForm',
    fields,
    validate,
})(Form);
